<template>
  <div>
    <ManageQRcode />
  </div>
</template>
<script>
import ManageQRcode from "@/components/QRcode/ManageQRcode";
export default {
  components: {
    ManageQRcode,
  },
  created() {
    // if (localStorage.getItem('user') === null) {
    //   this.$router.push('/Login').catch(() => {})
    // }
  },
};
</script>
