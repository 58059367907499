<template>
  <v-row no-gutters justify="center" align="center" class="mt-4">
    <v-card width="1200px">
      <v-container>
        <h2 class="my-5 headtitle">จัดการ QR code</h2>
        <v-row>
          <v-col cols="4" class="mr-4">
            <v-text-field
              class="mb-4"
              v-model="search"
              dense
              hide-details
              outlined
              placeholder="ค้นหา"
            ></v-text-field>
          </v-col>
          <v-spacer></v-spacer>
          <v-btn
            class="mx-4 mt-4"
            color="#833133"
            style="color: white"
            @click="createQuestion()"
            ><v-icon left dark> mdi-pencil </v-icon
            >แก้ไขการผูกQRCodeกับแบบสอบถาม
          </v-btn>
        </v-row>
        <v-data-table
          :headers="headers"
          :items="qrList"
          :search="search"
          :items-per-page="10"
          class="elevation-1"
        >
          <template v-slot:[`item.qrCode`]="{ item }">
            <vue-qrcode :value="item.url" max-width="30px" />
            <a @click="popup(item)">ดูรูปภาพขนาดใหญ่</a>
          </template>
          <template v-slot:[`item.url`]="{ item }">
            <a v-bind:href="item.url">{{ item.url }}</a>
          </template>
          <template v-slot:[`item.name`]="{ item }">
            <p>{{ item.name }} ({{ item.description }})</p>
          </template>
          <!-- <template v-slot:[`item.action`]="{ item }">
            <v-row justify="center" align="center">
              <v-btn @click="DeleteQuestion(item)" color="white"
                ><v-icon>mdi-delete</v-icon></v-btn
              >
            </v-row>
            <v-row justify="center" align="center">
              <v-btn @click="UpdateQuestion(item)" color="#11cdef" dark
                ><v-icon>mdi-pencil</v-icon></v-btn
              ></v-row
            >
            <v-row justify="center" align="center">
              <v-btn @click="GetAnswers(item)" color="#833133" dark
                ><v-icon>mdi-magnify</v-icon></v-btn
              >
            </v-row>
          </template> -->
        </v-data-table>
        <v-dialog v-model="dialog" max-width="600px">
          <v-card max-height="100%">
            <v-card-text>
              <v-col cols="12">
                <v-row align="center" justify="center"
                  ><vue-qrcode :value="QRImg" width="600" />
                  <v-spacer></v-spacer
                ></v-row>
                <v-col cols="12" align="right">
                  <v-btn
                    class="btn btn-secondary f-right"
                    color="#744D9c"
                    style="color: white"
                    @click="dialog = false"
                  >
                    ปิด
                  </v-btn>
                </v-col>
              </v-col>
            </v-card-text>
          </v-card>
        </v-dialog>
      </v-container>
    </v-card>

    <v-card width="1200px">
      <v-container>
        <h2 class="my-5 headtitle">QR code แจ้งเบาะแส</h2>
        <!-- <v-row>
          <v-col cols="4" class="mr-4">
            <v-text-field
              class="mb-4"
              v-model="search"
              dense
              hide-details
              outlined
              placeholder="ค้นหา"
            ></v-text-field>
          </v-col>
          <v-spacer></v-spacer>
        </v-row> -->
        <v-data-table
          :headers="headersDrug"
          :items="QRDrug"
          :search="search"
          :items-per-page="10"
          class="elevation-1"
        >
          <template v-slot:[`item.qrCode`]="{ item }">
            <vue-qrcode :value="item.formUrl" max-width="30px" />
            <a @click="popupDrug(item)">ดูรูปภาพขนาดใหญ่</a>
          </template>
          <template v-slot:[`item.url`]="{ item }">
            <a v-bind:href="item.formUrl">{{ item.formUrl }}</a>
          </template>
        </v-data-table>
        <v-dialog v-model="dialog" max-width="600px">
          <v-card max-height="100%">
            <v-card-text>
              <v-col cols="12">
                <v-row align="center" justify="center"
                  ><vue-qrcode :value="QRImgDrug" width="600" />
                  <v-spacer></v-spacer
                ></v-row>
                <v-col cols="12" align="right">
                  <v-btn
                    class="btn btn-secondary f-right"
                    color="#744D9c"
                    style="color: white"
                    @click="dialog = false"
                  >
                    ปิด
                  </v-btn>
                </v-col>
              </v-col>
            </v-card-text>
          </v-card>
        </v-dialog>
      </v-container>
    </v-card>
  </v-row>
</template>

<script>
import { Decode, Encode } from "@/services";
import VueQrcode from "vue-qrcode";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
export default {
  components: {
    VueQrcode,
    Loading,
  },
  data() {
    return {
      search: "",
      dialog: false,
      headers: [
        { text: "QR", value: "qrCode", align: "center" },
        { text: "station", value: "station", align: "center" },
        { text: "ลิงค์", value: "url", align: "center" },
        { text: "ชื่อแบบสอบถาม", value: "name", align: "center" },
        // { text: "ตัวเลือก", value: "action", align: "center" },
      ],
      headersDrug: [
        { text: "QR", value: "qrCode", align: "center" },
        { text: "ลิงค์", value: "url", align: "center" },
      ],
      qrList: [],
      formId: "",
      count: 0,
      QRImg: "",
      QRImgDrug: "",
      QRDrug: [],
    };
  },
  created() {
    this.getQR();
    this.getFormId();
  },
  methods: {
    popup(item) {
      this.dialog = !this.dialog;
      this.QRImg = item.url;
      console.log("image", item);
    },
    popupDrug(item) {
      this.dialog = !this.dialog;
      this.QRImgDrug = item.formUrl;
      console.log("image", item);
    },
    getFormId() {
      const response = JSON.parse(
        Decode.decode(localStorage.getItem("Questions"))
      );
      // this.list1 = response.question;
      this.formId = response.id;
      // console.log("response", response);
      // console.log("list1", this.list1);
    },
    async getQR() {
      // const response = await this.axios.get(
      //   `${process.env.VUE_APP_API}/form/getAllQrCode/`
      // );
      // console.log("response", response.data.data);
      // this.list1 = response.data.data;
      //   for (let i in this.list1) {
      //     this.list1[i].count = parseInt(i) + 1;
      //   }
      const url = this.$router.currentRoute.query;
      if (url.station) {
        const response = await this.axios.get(
          // `${process.env.VUE_APP_API}/form/getAllQrCode`
          `${process.env.VUE_APP_API}/form/getAllQrCode?station=` + url.station
        );
        console.log("response", response);
        this.qrList = response.data.data;
        this.qrList.forEach((qr) => {
          qr.url = `${process.env.VUE_APP_FRONTEND}/survey?qrCodeId=` + qr.id;
        });
        this.QRDrug = response.data.drugQR;
      } else {
        const response = await this.axios.get(
          // `${process.env.VUE_APP_API}/form/getAllQrCode`
          `${process.env.VUE_APP_API}/form/getAllQrCode`
        );
        console.log("response QR", response);
        this.qrList = response.data.data;
        this.qrList.forEach((qr) => {
          qr.url = `${process.env.VUE_APP_FRONTEND}/survey?qrCodeId=` + qr.id;
        });
        this.QRDrug = response.data.drugQR;
      }
    },
    async DeleteQuestion(val) {
      this.$swal({
        text: "คุณต้องการลบข้อมูลใช่ หรือไม่ ?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: "ยกเลิก",
        confirmButtonText: "ต้องการลบข้อมูล",
      }).then(async (result) => {
        if (result.isConfirmed) {
          const response = await this.axios.get(
            `${process.env.VUE_APP_API}/form/removeQuestion/` + val.id
          );
          console.log(response);

          this.$swal.fire({
            icon: "success",
            text: "ลบข้อมูลสำเร็จ",
            showConfirmButton: false,
            timer: 1000,
          });
          await location.reload();
        }
      });
    },
    UpdateQuestion(val) {
      console.log("val", val);
      localStorage.setItem("UpdateQuestion", Encode.encode(val));
      this.$router.push("UpdateQuestion");
    },
    createQuestion() {
      this.$router.push("EditQRCode");
    },
  },
};
</script>
<style scoped>
.headtitle {
  font-weight: 800;
  color: #833133;
}
</style>