var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',{staticClass:"mt-4",attrs:{"no-gutters":"","justify":"center","align":"center"}},[_c('v-card',{attrs:{"width":"1200px"}},[_c('v-container',[_c('h2',{staticClass:"my-5 headtitle"},[_vm._v("จัดการ QR code")]),_c('v-row',[_c('v-col',{staticClass:"mr-4",attrs:{"cols":"4"}},[_c('v-text-field',{staticClass:"mb-4",attrs:{"dense":"","hide-details":"","outlined":"","placeholder":"ค้นหา"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-spacer'),_c('v-btn',{staticClass:"mx-4 mt-4",staticStyle:{"color":"white"},attrs:{"color":"#833133"},on:{"click":function($event){return _vm.createQuestion()}}},[_c('v-icon',{attrs:{"left":"","dark":""}},[_vm._v(" mdi-pencil ")]),_vm._v("แก้ไขการผูกQRCodeกับแบบสอบถาม ")],1)],1),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.qrList,"search":_vm.search,"items-per-page":10},scopedSlots:_vm._u([{key:"item.qrCode",fn:function(ref){
var item = ref.item;
return [_c('vue-qrcode',{attrs:{"value":item.url,"max-width":"30px"}}),_c('a',{on:{"click":function($event){return _vm.popup(item)}}},[_vm._v("ดูรูปภาพขนาดใหญ่")])]}},{key:"item.url",fn:function(ref){
var item = ref.item;
return [_c('a',{attrs:{"href":item.url}},[_vm._v(_vm._s(item.url))])]}},{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('p',[_vm._v(_vm._s(item.name)+" ("+_vm._s(item.description)+")")])]}}],null,true)}),_c('v-dialog',{attrs:{"max-width":"600px"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',{attrs:{"max-height":"100%"}},[_c('v-card-text',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-row',{attrs:{"align":"center","justify":"center"}},[_c('vue-qrcode',{attrs:{"value":_vm.QRImg,"width":"600"}}),_c('v-spacer')],1),_c('v-col',{attrs:{"cols":"12","align":"right"}},[_c('v-btn',{staticClass:"btn btn-secondary f-right",staticStyle:{"color":"white"},attrs:{"color":"#744D9c"},on:{"click":function($event){_vm.dialog = false}}},[_vm._v(" ปิด ")])],1)],1)],1)],1)],1)],1)],1),_c('v-card',{attrs:{"width":"1200px"}},[_c('v-container',[_c('h2',{staticClass:"my-5 headtitle"},[_vm._v("QR code แจ้งเบาะแส")]),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headersDrug,"items":_vm.QRDrug,"search":_vm.search,"items-per-page":10},scopedSlots:_vm._u([{key:"item.qrCode",fn:function(ref){
var item = ref.item;
return [_c('vue-qrcode',{attrs:{"value":item.formUrl,"max-width":"30px"}}),_c('a',{on:{"click":function($event){return _vm.popupDrug(item)}}},[_vm._v("ดูรูปภาพขนาดใหญ่")])]}},{key:"item.url",fn:function(ref){
var item = ref.item;
return [_c('a',{attrs:{"href":item.formUrl}},[_vm._v(_vm._s(item.formUrl))])]}}],null,true)}),_c('v-dialog',{attrs:{"max-width":"600px"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',{attrs:{"max-height":"100%"}},[_c('v-card-text',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-row',{attrs:{"align":"center","justify":"center"}},[_c('vue-qrcode',{attrs:{"value":_vm.QRImgDrug,"width":"600"}}),_c('v-spacer')],1),_c('v-col',{attrs:{"cols":"12","align":"right"}},[_c('v-btn',{staticClass:"btn btn-secondary f-right",staticStyle:{"color":"white"},attrs:{"color":"#744D9c"},on:{"click":function($event){_vm.dialog = false}}},[_vm._v(" ปิด ")])],1)],1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }